import * as THREE from 'three';
import SiteLoader from './SiteLoader.js';
import { TweenLite } from "gsap"; 
import GuiController from './GuiController.js'

import PostPro from './PostPro.js'
import { Water } from './water.js';
import { OrbitControls } from './OrbitControls.js';
import { Sky } from './sky.js';

//import { NightSky } from './NightSky.js';
import Ship from './ship.js';
import soundtrackSound from '../sounds/soundtrack.mp3';
import thunderSound from '../sounds/thunder.mp3';
import Hotspots from './Hotspots.js';
import Overlay from './Overlay.js';
import Clouds from './Clouds';

import Stats from 'stats.js';
import { EventDispatcher } from './EventDispatcher.js';
import Rain from './Rain.js';
import {Howl, Howler} from 'howler';
import Localisation from './Localisation.js';
import { Tween } from 'gsap/gsap-core.js';
let container, stats;
let camera, scene, renderer,sceneSprite;
let controls, water, sun, mesh, nightSky,ship,hotspots,overlay,clouds;
let raycaster = new THREE.Raycaster();
let mouse = new THREE.Vector2();
let composer;
let game;
let postPro_;
let sky;
let renderTarget,pmremGenerator;
let renderFrames = true;
const parameters = {
	elevation: 3,
	azimuth: -80,
	isNight: false
};
let centralDispatcher,loader;
let isMobile
let clock;
let audioBk;
let rain;
let audioThunder;
let muted=false;
let footer = document.querySelector('footer');
let cursorPosition = null;
let frames = 0;
let ratioSet=false;
let raycast = false;
let firstPlay = true;
const localise = ()=>{

	let localisation = new Localisation( preload );

}

let preload = ()=>{

	detectDevice();
	
	centralDispatcher = new EventDispatcher();
	loader = new SiteLoader();
	loader.loadAssets().then(()=>{

			init();
			animate();
	});

}

const detectDevice = ()=>{

	isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	var md = new MobileDetect(window.navigator.userAgent);
	var deviceType;
	
	if (md.phone()) {
	    deviceType = "phone";
	} else if (md.tablet()) {
	    deviceType = "tablet";
	} else {
	    deviceType = "desktop";
	}
	window.deviceType = deviceType
	document.body.classList.add( deviceType );

}

localise();
function init() {

	///////////////////////////////////////////////
	/// INIT SCENE
	container = document.getElementById( 'container' );
	document.getElementById( 'container' ).style.display = "none";
	
	renderer = new THREE.WebGLRenderer();
	renderer.setPixelRatio( 1.5 );
	renderer.setClearColor(0x000000, 0);
	renderer.setSize( window.innerWidth, window.innerHeight );
	renderer.toneMapping = THREE.ACESFilmicToneMapping;
	renderer.toneMappingExposure = 0.5;
	container.appendChild( renderer.domElement );

	scene = new THREE.Scene();

	/*// Scene with sprites (without post-processing)
	sceneSprite= new THREE.Scene();
		let map = new THREE.TextureLoader().load( hotspotTextureURL );
		let material = new THREE.SpriteMaterial( { map: map,transparent:true } );
		const hotspot = new THREE.Sprite( material );
		hotspot.scale.set(60,60)
	sceneSprite.add(hotspot)*/

	clock = new THREE.Clock();

	let camPerspective=60
	if(isMobile) camPerspective = 70

	camera = new THREE.PerspectiveCamera( camPerspective, window.innerWidth / window.innerHeight, 1, 1000 );
	camera.position.set(119,  14.9, 38.8 );

	/////////////////////////////////////
	//LIGHTS
	/////////////////////////////////////
	var ambientLight = new THREE.AmbientLight(0xffffff, 3); 
	scene.add(ambientLight);
	
	var directionalLight = new THREE.DirectionalLight(0xffffff, 7); 
	directionalLight.position.set(-43, 2, 0); // Set the position of the light
	scene.add(directionalLight);
	
	var directionalLight2 = new THREE.DirectionalLight(0xffffff, 7); 
	directionalLight2.position.set(8, 30, 0); // Set the position of the light
	scene.add(directionalLight2);
	const sphere = new THREE.SphereGeometry( 1, 16, 8 );

	/////////////////////////////////////
	// SUN
	/////////////////////////////////////

	sun = new THREE.Vector3();
	
	/////////////////////////////////////
	// WATER
	/////////////////////////////////////

	water = new Water();
	water.rotation.x = - Math.PI / 2;
	scene.add( water );


	/////////////////////////////////////
	// SKYBOX
	/////////////////////////////////////

	sky= new Sky();
	sky.scale.setScalar( 500 );
	scene.add( sky );
	const skyUniforms = sky.material.uniforms;
	skyUniforms[ 'turbidity' ].value = 200;
	skyUniforms[ 'rayleigh' ].value = 2;
	skyUniforms[ 'mieCoefficient' ].value = 0.005;
	skyUniforms[ 'mieDirectionalG' ].value = 0.8;

	//nightSky = new NightSky();
    

	pmremGenerator = new THREE.PMREMGenerator( renderer );

	/////////////////////////////////////
	// SHIP
	/////////////////////////////////////
	
	ship = new Ship(scene);
	ship.load();

	/////////////////////////////////////
	// CONTROLS
	/////////////////////////////////////

	controls = new OrbitControls( camera, renderer.domElement );
	controls.maxPolarAngle = Math.PI * 0.495;
	controls.target.set(  0, 4.19, -4.5 );
	controls.minDistance = 100.0;
	controls.maxDistance = 200.0;
	controls.enablePan = false;
	controls.update();
	
	/////////////////////////////////////
	// STATS
	/////////////////////////////////////
	//stats = new Stats();
	//container.appendChild( stats.dom );


	
	window.addEventListener( 'resize', onWindowResize );

    initSound()


	
	overlay = new Overlay(controls,centralDispatcher);
	hotspots = new Hotspots(scene, controls, camera,overlay,centralDispatcher);
	clouds = new Clouds(scene);
	postPro_ = new PostPro(scene,renderer,camera,onReady)
	rain = new Rain(centralDispatcher);


	playTension();
	updateSun();
//	camera.position.set(119,  14.9, 38.8 );
	controls.enabled = false;
	Tween.fromTo(camera.position,6,{x:400,y:60},{x:119,y:14.9,onComplete:()=>{
		document.body.style.pointerEvents="auto";
		raycast=true;
		controls.enabled = true;
		hotspots.fadeIn();

	}})
	////////////////////////////////////////////
	// GUI
	//////////////////////////////////////////
	//const gui = new GuiController( parameters, water, updateSun, ambientLight,directionalLight,directionalLight2,postPro_ );

window.addEventListener('wheel', (e) => {

	// if user scrolls to bottom of the page and cursor is within 200px of the bottom of the window
	if ((window.innerHeight - cursorPosition) <= 200) {
	 
	  footer.classList.add('footer_show');
	  document.querySelector('.social').classList.add( 'footer-show')
	}
  });
  	document.getElementById("close_footer").addEventListener("click",()=>{
		footer.classList.remove('footer_show');
		document.querySelector('.social').classList.remove( 'footer-show')

	})

	window.addEventListener('touchstart', (e) => {
		cursorPosition = e.touches[0].clientY;
	  }, false);
	  
	  window.addEventListener('touchmove', (e) => {
		if ((window.innerHeight - cursorPosition) <= 100) {
		  footer.classList.add('footer_show');
		}
	  }, false);

	window.addEventListener('pointerdown', onMouseClick, false);
	window.addEventListener('mousemove', onMouseMove, false);

	document.getElementById("soundBTN").addEventListener("click",onSoundToggle)
	centralDispatcher.addEventListener("overlayShow",onStopRender)
	centralDispatcher.addEventListener("OverlayClosed",onStartRender)

	
	centralDispatcher.addEventListener("THUNDER",playThunder)
	
document.addEventListener('visibilitychange', function() {
	if (document.hidden) {
	  // if the tab is not active, mute all sounds
	  Howler.mute(true);
	} else {
	  // if the tab is active again, unmute all sounds
	  if(!muted)Howler.mute(false);
	}
  });

   
}
function onStopRender(event){

	if(event.data){
		if (!overlay.data[event.data].images) {
			pauseTension();
		}
	}
	
	renderFrames = false
	console.log('stop')
}
function onStartRender(event){
	playTension();
	renderFrames = true
	animate();
}

function onReady(){
	loader.hide();
	document.getElementById( 'container' ).style.display="block";
}


function updateSun() {

	const phi = THREE.MathUtils.degToRad( 90 - parameters.elevation );
	const theta = THREE.MathUtils.degToRad( parameters.azimuth );
	sun.setFromSphericalCoords( 1, phi, theta );
	sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
	water.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();
	if ( renderTarget !== undefined ) renderTarget.dispose();
	renderTarget = pmremGenerator.fromScene( sky );
	scene.environment = renderTarget.texture;

}
/*
function changeDay(value) {

	
	if (value.data) { // Night mode
	
		parameters.elevation = -30;
		updateSun()
		//scene.remove(sky);
		scene.add(nightSky);
	} else { // Day mode
		parameters.elevation = 3;
		updateSun()
		scene.remove(nightSky);
		scene.add(sky);
	}
};
*/
function initSound() {
	audioThunder = new Howl({
		src: [thunderSound]
	  });
	  audioThunder.volume(0.01);
	audioBk = new Howl({
		src: [soundtrackSound],
		loop:true
	  });
	  audioBk.volume(0.5);


	audioBk.on('play', function(){
  		if (firstPlay) {
			document.querySelector('.soundOn').classList.remove('hidden')
			document.querySelector('.soundOff').classList.add('hidden')
    		firstPlay = false;
  	}
});

	  audioBk.play();
}

function playThunder(){
	  audioThunder.volume(0.1);
	audioThunder.play();
}
function onWindowResize() {
	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setSize( window.innerWidth, window.innerHeight );
}
function animate() {

	if(renderFrames){
	
		requestAnimationFrame( animate );
		let delta = clock.getDelta(); 

		if(!ratioSet){
		
			if (clock.elapsedTime <= 10) {
				frames++;
			} else{
				if (frames  < 250) { // or whatever minimum fps you want to target
		
					renderer.setPixelRatio(1);
					
				}
				ratioSet = true;

			} 
		
		}
		render();
	//	if (controls.target.y < 5) {
	//		controls.target.y = 5;
	//	}
		if(rain)  rain.update(delta); // animate the rain)
		if(ship) ship.update( delta );
		if(hotspots) hotspots.update(delta);
		if(clouds)clouds.update();
		//stats.update();
	
	}
}
function render() {
	//const time = performance.now() * 0.001;
	
//	let t0 = performance.now();
	water.material.uniforms[ 'time' ].value  += 0.0166667;
//	let t1 = performance.now();
//	console.log(`Call to water.material.uniforms['time'].value took ${t1 - t0} milliseconds.`);
	

	//.clearDepth()
	//renderer.autoClear = false;
	//renderer.clear();
	postPro_.composer.render();
  
	///renderer.clearDepth();
	//renderer.render( sceneSprite, camera );
}

function onMouseMove(event){
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
	cursorPosition = event.clientY;
    // Update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(hotspots.hotspotContainer.children, true);

    // Assume no intersections
    let intersectionFound = false;

    for (let i = 0; i < intersects.length; i++) {
        // If the intersected object is a hotspot
        if (hotspots.isHotspot(intersects[i].object)) {
            // Change the cursor to pointer on hotspot intersection
            document.body.style.cursor = 'pointer';
            intersectionFound = true;
            break;
        }
    }

    // If no intersection found, revert the cursor to default style
    if (!intersectionFound) {
        document.body.style.cursor = 'auto';
    }
}


function onMouseClick(event) {
	if(!raycast)return;
	if(!renderFrames)return
    // Calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    // Update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera);

    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(hotspots.hotspotContainer.children, true);
//	const intersects2 = raycaster.intersectObjects(scene.children, true);



	for (let i = 0; i < intersects.length; i++) {
		console.log( intersects[i].object.name)
		// If the intersected object is a hotspot
		if (hotspots.isHotspot(intersects[i].object)) { 
			console.log("click")
			// 'hotspots' should be a reference to your Hotspots instance
			console.log( intersects[i].object)
			console.log( intersects[i].object.data)
			centralDispatcher.dispatchEvent( { type: 'HotSpotFound', data: intersects[i].object.data } );
			return
		}
	  }
}

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

function playTension(){
	audioBk.play();
	//audioTension.seek(0)
	//audioTension.play();
	//audioBk.play();
}
function pauseTension(){
	audioBk.pause();
	//audioTension.pause();
	//audioThunder.pause();
}

function onSoundToggle(){
	if(muted){
		document.querySelector('.soundOn').classList.remove('hidden')
		document.querySelector('.soundOff').classList.add('hidden')
		Howler.mute(false)
	}else{
		document.querySelector('.soundOn').classList.add('hidden')
		document.querySelector('.soundOff').classList.remove('hidden')
		Howler.mute(true)
	}
	muted=!muted;
}

function toggleMenu(){
	const burgerMenu = document.querySelector('.burger-menu');
	burgerMenu.classList.toggle('open');
	if(burgerMenu.classList.contains('open')){
		document.querySelector('.epk-menu').classList.add("epk-menu-show")
	}else{
		document.querySelector('.epk-menu').classList.remove("epk-menu-show")
	}
	
}