import { GUI } from 'lil-gui';
import * as THREE from 'three';


class GuiController {

	constructor( parameters, water, updateSun, ambientLight,directionalLight ,directionalLight2,post) {
        this.gui = new GUI();
        
        const folderSky = this.gui.addFolder( 'Sky' );
        folderSky.add( parameters, 'elevation', -40, 90, 0.1 ).onChange( updateSun ).setValue(3);
        folderSky.add( parameters, 'azimuth', - 180, 180, 0.1 ).onChange( updateSun ).setValue(-80);
        folderSky.open();

        const waterUniforms = water.material.uniforms;
        const folderWater = this.gui.addFolder( 'Water' );
        folderWater.add( waterUniforms.distortionScale, 'value', 0, 80, 0.1 ).name( 'distortionScale' ).setValue(8);
        folderWater.add( waterUniforms.size, 'value', 0.1, 10, 0.1 ).name( 'size' ).setValue(10);
        folderWater.open();


  //      let nightController = this.gui.add(parameters, 'isNight').name('Night Mode').listen();
   //     nightController.onChange(changeDay)


        const folderAmbientLight = this.gui.addFolder( 'Ambient Light' );
        folderAmbientLight.add(ambientLight, 'intensity', 0, 50, 0.1);
        folderAmbientLight.addColor({hex: `#${ambientLight.color.getHexString()}`}, 'hex').onChange(value => {
            ambientLight.color.set(value);
        });
        folderAmbientLight.open();

        const folderDirectionalLight = this.gui.addFolder( 'Directional Light' );
        folderDirectionalLight.add(directionalLight, 'intensity', 0, 50, 0.1);
        folderDirectionalLight.addColor({hex: `#${directionalLight.color.getHexString()}`}, 'hex').onChange(value => {
            directionalLight.color.set(value);
        });
        folderDirectionalLight.add(directionalLight.position, 'x', -50, 50, 1).name('position x');
        folderDirectionalLight.add(directionalLight.position, 'y', -50, 50, 1).name('position y');
        folderDirectionalLight.add(directionalLight.position, 'z', -50, 50, 1).name('position z');
        folderDirectionalLight.open();


        const folderDirectionalLight2 = this.gui.addFolder( 'Directional Light 2' );
        folderDirectionalLight2.add(directionalLight2, 'intensity', 0, 50, 0.1);
        folderDirectionalLight2.addColor({hex: `#${directionalLight2.color.getHexString()}`}, 'hex').onChange(value => {
            directionalLight2.color.set(value);
        });
        folderDirectionalLight2.add(directionalLight2.position, 'x', -50, 50, 1).name('position x');
        folderDirectionalLight2.add(directionalLight2.position, 'y', -50, 50, 1).name('position y');
        folderDirectionalLight2.add(directionalLight2.position, 'z', -50, 50, 1).name('position z');
        folderDirectionalLight2.open();

        console.log( post )
        const folderPost =  this.gui.addFolder( 'postprocessing' );
        folderPost.add(post.shaderUniforms, 'brightness', 0, 2).name('Brightness').onChange(() => {
            post.colorPass.uniforms.brightness.value = post.shaderUniforms.brightness;

       });
       folderPost.add(post.shaderUniforms, 'contrast', 0, 2).name('Contrast').onChange(() => {
            post.colorPass.uniforms.contrast.value = post.shaderUniforms.contrast;
       });
       folderPost.add(post.shaderUniforms, 'saturation', 0, 2).name('Saturation').onChange(() => {
            post.colorPass.uniforms.saturation.value = post.shaderUniforms.saturation;
       });
       folderPost.add(post.shaderUniforms, 'hue', 0, 1).name('Hue').onChange(() => {
            post.colorPass.uniforms.hue.value = post.shaderUniforms.hue;
       });
 
       folderPost.add(post.params, 'intensity').min(0).max(1).onChange((value) => {
        post.lutPass.intensity = post.params.intensity;
    });
 
    folderPost.add(post.shaderUniforms, 'vignetteOffset', 0, 2).name('Vignette Offset').onChange(() => {
        post.vignettePass.uniforms.offset.value = post.shaderUniforms.vignetteOffset;
    });
    folderPost.add(post.shaderUniforms, 'vignetteDarkness', 0, 3).name('Vignette Darkness').onChange(() => {
        post.vignettePass.uniforms.darkness.value = post.shaderUniforms.vignetteDarkness;
    });
        




        this.gui.close();
    }

}

export default GuiController;