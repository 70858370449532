 class Rain {
    constructor(centralDispatcher) {
     this.centralDispatcher = centralDispatcher;

      this.canvas = document.getElementById("canvas");
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      this.ctx = this.canvas.getContext('2d');
      this.w = this.canvas.width;
      this.h = this.canvas.height;
      this.ctx.strokeStyle = 'rgba(174,194,224,0.1)';
      if(document.body.classList.contains("phone")){
        this.ctx.strokeStyle = 'rgba(174,194,224,0.05)';
      }
      this.ctx.lineWidth = 1;
      this.ctx.lineCap = 'round';
      
      this.init = [];
      this.maxParts = 700;
      for(var a = 0; a < this.maxParts; a++) {
        this.init.push({
          x: Math.random() * this.w,
          y: Math.random() * this.h,
          l: 10+ Math.random() * 4,
          xs: -4 + Math.random() * 4 + 2,
          ys: Math.random() * 10 + 10
        })
      }
      
      this.particles = [];
      for(var b = 0; b < this.maxParts; b++) {
        this.particles[b] = this.init[b];
      }

        // Lightning settings
    this.lightningProbability = 0.002; // Adjust as needed
    this.lightningDuration = 0.1; // In seconds
    this.lightningTimer = 0;
    }
  
    draw() {
      this.ctx.clearRect(0, 0, this.w, this.h);
      for(var c = 0; c < this.particles.length; c++) {
        var p = this.particles[c];
        this.ctx.beginPath();
        this.ctx.moveTo(p.x, p.y);
        this.ctx.lineTo(p.x + p.l * p.xs, p.y + p.l * p.ys);
        this.ctx.stroke();
      }
      this.move();
    }
  
    move() {
      for(var b = 0; b < this.particles.length; b++) {
        var p = this.particles[b];
        p.x += p.xs*1;
        p.y += p.ys*6;
        if(p.x > this.w || p.y > this.h) {
          p.x = Math.random() * this.w;
          p.y = -30;
        }
      }
    }
  
    update(deltaTime) {
        this.draw();
        this.lightningTimer -= deltaTime;
        if(this.lightningTimer < 0) {
          if(Math.random() < this.lightningProbability) {
            this.centralDispatcher.dispatchEvent( { type: 'THUNDER', data: "" } );
            this.lightningTimer = this.lightningDuration;
          }
        }
        if(this.lightningTimer > 0) {
          this.ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
          this.ctx.fillRect(0, 0, this.w, this.h);
        }
      }
  }

  export default Rain;