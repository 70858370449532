import * as THREE from 'three';
import vertexShader from '../shaders/vertex.glsl';
import fragmentShader from '../shaders/fragment.glsl';
import cloudTextureURL from '../textures/cloud1.png'; // Your texture file path
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
class Clouds {
  constructor(scene) {
    this.check= 0;
      this.scene = scene;
      this.clouds = [];

      this.initClouds();
  }

  initClouds() {
    const texture = new THREE.TextureLoader().load(cloudTextureURL);

    const width = 2000;  // Set the width of the cloud field
    const height = 100;  // Set the height of the cloud field
    const depth = 2000;  // Set the depth of the cloud field
    this.cloudHolder = new THREE.Group();
    for (let i = 0; i < 400; i++) {
        const x = Math.random() * width - width / 2;
        const y =50+Math.random()*100;
        const z = Math.random() * depth - depth / 2;

        const spriteMaterial = new THREE.SpriteMaterial({ map: texture, transparent: true, opacity: 0.3 });  // 50% opacity
        const sprite = new THREE.Sprite(spriteMaterial);
        let sc = Math.random()*40+100
        sprite.scale.set(sc,sc, 1);  // Set appropriate scale for your scene
        sprite.position.set(x, y, z);

        this.clouds.push(sprite);
        this.cloudHolder.add(sprite)
      
    }
   // this.cloudHolder.position.y =-30.05;
    window.clouds = this.cloudHolder;
    this.scene.add(this.cloudHolder);
}


  update(camera, mouseX, mouseY) {
    this.check++;
    this.cloudHolder.position.z -=0.1;
  if(this.check==100) this.checkClouds();
    // Update the position of the camera and the mesh based on the mouse position
 /*   camera.position.x += (mouseX - camera.position.x) * 0.01;
    camera.position.y += (-mouseY - camera.position.y) * 0.01;
    camera.position.z = -position + 8000;

    this.mesh.position.z = -8000;*/
  }
  checkClouds(){
    this.check=0
    for( let i = 0;i<this.cloudHolder.children.length;i++){
      if( this.cloudHolder.position.z+ this.cloudHolder.children[i].position.z<-1000){

        this.cloudHolder.children[i].position.z =- this.cloudHolder.position.z+ 10+Math.random()*100
      }
    }
  }
}

export default Clouds;
