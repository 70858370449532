/**
 * Overlay class is responsible for controlling the video overlay functionality.
 */
import Splide from '@splidejs/splide';
class Overlay {
  /**
   * Constructor to initialize the Overlay class.
   * @param {Object} controls 
   * @param {Object} centralDispatcher 
   */
  constructor(controls, centralDispatcher) {
    this.controls = controls;
    this.centralDispatcher = centralDispatcher;
    this.limit = 3;
    // Register event listener for "overlayShow" event
    centralDispatcher.addEventListener("overlayShow", this.show.bind(this));

    // Get the video data from i18next
    this.data = window.i18next.t('videos', { returnObjects: true });
    this.scareCount= 0;
    // Initialize overlay
    this.initOverlay();
  }

  /**
   * Function to initialize the Overlay.
   */
  initOverlay() {
    // Get DOM elements
    this.videoHolder = document.querySelector('.videoHolder');
    this.imageHolder = document.querySelector('.imageHolder');
    this.overlay = document.getElementById('videoOverlay');
    this.closeButton = document.getElementById('videoClose');
    this.closeButtonGal = document.getElementById('videoCloseGallery');
    
    
    this.video = document.getElementById('videoOverlayTag');
    this.gallery = document.querySelector('.gallery');
    // Add event listeners to closeButton and video
    this.closeButton.addEventListener('click', this.hide.bind(this));
    this.closeButtonGal.addEventListener('click', this.hide2.bind(this));

  //  this.video.addEventListener('click', this.togglePause.bind(this));
  }

  /**
   * Function to toggle video play/pause status.
   * @param {Event} event 
   */
  togglePause(event) {
    if (this.video.paused) {
      this.video.play();
    } else {
      this.video.pause();
    }
  }

  /**
   * Function to display the overlay and play the video.
   * @param {Event} event 
   */

  show(event) {
    this.scareCount++;
    if(this.scareCount === this.limit){
      this.showScare();
      this.limit= 7;
      return;
    }
    this.centralDispatcher.dispatchEvent( { type: 'changeHotspotColor', data: event.data } );
    if (this.data[event.data].images) { 
      this.showImageGallery(event);
    } else {
      this.showVideo(event);
    }
  }
  showImageGallery(event) {
    if(this.data[event.data].caption!=null){
      document.getElementById("videoCaption").classList.remove("hidden")
     }
    this.closeButtonGal.classList.remove("hidden")
     this.videoHolder.classList.add('hidden');
     
     this.imageHolder.classList.remove('hidden');

     const track = document.createElement('div');
     track.classList.add('splide__track');
     
     const list = document.createElement('ul');
     list.classList.add('splide__list');
     
     // Populate the list with images
     this.data[event.data].images.forEach(img => {
     
       const listItem = document.createElement('li');
       listItem.classList.add('splide__slide');
       const image = new Image();
       image.src = img;
       image.alt = "Slide image";
       const preloader = document.createElement('div');
       preloader.classList.add('preloader2');
       image.onload = () => {
        document.querySelectorAll('.preloader2').forEach(element => element.remove());
      //  preloader.style.display = 'none!important';
      };
      listItem.appendChild(preloader);
      listItem.appendChild(image);
       //   listItem.innerHTML =  `<img src="${img}" alt="Slide image">`;
       list.appendChild(listItem); // Append the slide items to the splide__list
     });
     
     // Append the list to the track and the track to the gallery
     track.appendChild(list);
     this.gallery.appendChild(track);
     
     // Initialize Splide
     this.splide = new Splide('.gallery', { 
       type: 'slide',
       perPage: 1, 
       perMove: 1,
       pagination: true
     });
     this.splide.mount();
     // Display overlay and disable controls
    this.overlay.classList.remove("hidden");
    this.overlay.style.display = 'flex';
    document.getElementById("videoCaption").innerHTML = this.data[event.data].caption;
    
 
    setTimeout(()=>{
      if(this.data[event.data].caption==null){
        document.querySelector(".splide__track").style.pointerEvents ="none"
        document.querySelector(".splide__arrows").classList.add("hidden");
        document.getElementById("videoCaption").classList.add("hidden")
      
       }else{
        document.getElementById("videoCaption").classList.add("show")
       }
       
      this.gallery.classList.add("show")
      document.getElementById("videoCloseGallery").classList.add("show")
    },600)



    if(window.deviceType =="phone"){
    setTimeout(()=>{
      var overlay = document.querySelector('.videoOverlay'); // adjust this if necessary
      var contentHeight = overlay.scrollHeight;
      var viewportHeight = window.innerHeight;
  
      if(contentHeight > viewportHeight) {
          overlay.style.paddingTop = '50px'; // add the padding if the content is tall enough to require scrolling
      } else {
          overlay.style.paddingTop = '0'; // don't add the padding if the content is not tall enough to require scrolling
      }
    },1000)
    
     
  
     }
 
  }
  
  showVideo(event) {

    this.imageHolder.classList.add('hidden');
    this.videoHolder.classList.remove('hidden');
    document.getElementById("videoClose").classList.remove("hidden")
    document.getElementById("videoCaption").classList.remove("hidden")
    this.videoHolder.classList.remove("fullscreen-video") 
    this.video.querySelector("source").src = this.data[event.data].url;
    this.video.controls="controls";
    // Display overlay and disable controls
    this.overlay.classList.remove("hidden");
    this.overlay.style.display = 'flex';
    this.controls.enabled = false;

    this.video.load();
     this.video.play();
    document.getElementById("videoCaption").innerHTML = this.data[event.data].caption;
    setTimeout(()=>{
      document.getElementById("videoCaption").classList.add("show")
      this.videoHolder.classList.add("show")
      },600)

  }

  
  
  
  showScare(){
    this.imageHolder.classList.add('hidden')
    this.videoHolder.classList.remove('hidden');
    this.closeButtonGal.classList.add("hidden")
    this.video.querySelector("source").src = "assets/video/jumpscare.mp4"
    document.getElementById("videoClose").classList.add("hidden")
    document.getElementById("videoCaption").classList.add("hidden")
    this.videoHolder.classList.add("fullscreen-video") 
    this.video.controls=null;
    this.video.load();
    this.onVideoEndBound = this.onVideoEnd.bind(this);
    this.video.addEventListener('ended', this.onVideoEndBound);
   this.video.play();
    this.scareCount = 0;
       // Display overlay and disable controls
       this.overlay.classList.remove("hidden");
       this.overlay.style.display = 'flex';
       this.controls.enabled = false;
   
  }
  /**
   * Function to hide the overlay and pause the video.
   */
  onVideoEnd() {
    this.video.removeEventListener('ended', this.onVideoEndBound);
    this.hide();
  }

  hide() {

    // Hide overlay and enable controls
    this.overlay.style.display = 'none';
    this.controls.enabled = true;

    // Pause video and dispatch OverlayClosed event
    this.video.pause();
    document.getElementById("videoCaption").classList.remove("show")
    this.videoHolder.classList.remove("show")
    this.centralDispatcher.dispatchEvent( { type: 'OverlayClosed', data: "" } );
  }
  hide2(){
    this.gallery.innerHTML = '';
      this.overlay.style.display = 'none';
      this.centralDispatcher.dispatchEvent( { type: 'OverlayClosed', data: "" } );
      document.getElementById("videoCaption").classList.remove("show")
      document.getElementById("videoCloseGallery").classList.remove("show")
      this.gallery.classList.remove("show")
  }
}

export default Overlay;
