import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import shipURL from '../models/ship5.glb';
import waterNormalsURL from '../textures/waternormals.jpg';
import cubicle from '../assets/lut/Cubicle 99.CUBE';

class SiteLoader {
  constructor() {

    this.gltfLoader = new GLTFLoader();
    this.gltfLoader2 = new GLTFLoader();
    this.textureLoader = new THREE.TextureLoader();
    this.fileLoader = new THREE.FileLoader();

    this.totalLoadedBytes = 0;
      console.log('initialise')                
    this.totalBytes =12896337//13785493//14216478//14308546//14308546 //3448386//14679915//12168765//66298636; /// 66370008;
    this.container = document.getElementById('loader-container');
    this.progressBar = document.getElementById('progress-bar');
   // this.quote = document.getElementById('loading-quote');

    this.quotes = [
      "The suffocating fog closed in, trapping us in its cold, clammy embrace.",
      "As the waves crashed against the pier, I felt trapped, surrounded by the relentless sea.",
      "In the darkness of the hold, I could feel the walls closing in, squeezing the air out of my lungs.",
      "The narrow cabin became a prison, the silence suffocating, and the walls closing in on me.",
      "The howling wind echoed through the tight corridors, a haunting reminder of our claustrophobic confinement.",
      "In the midst of the storm, the ship felt like a suffocating coffin, sealing us in its watery grave.",
      "As the dense mist enveloped us, I couldn't shake off the feeling of being trapped in a never-ending nightmare."
    ];
    this.loadModel = this.loadModel.bind(this);
    this.loadModel2 = this.loadModel2.bind(this);
    this.loadTexture = this.loadTexture.bind(this);
    this.loadLUT = this.loadLUT.bind(this);
    this.updateProgressBar = this.updateProgressBar.bind(this);
    this.updateQuote = this.updateQuote.bind(this);
    this.printTotalLoadedBytes = this.printTotalLoadedBytes.bind(this);
    this.calculateProgress = this.calculateProgress.bind(this);

    this.updateProgressBar(this.calculateProgress());

  }

  loadLUT(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
  
      let previousLoadedBytes = 0;
  
      xhr.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          
          const newLoadedBytes = event.loaded - previousLoadedBytes;
          this.totalLoadedBytes += newLoadedBytes;
          previousLoadedBytes = event.loaded
       //   this.calculatedTotalBytes += event.total;
          this.updateProgressBar(this.calculateProgress());
        }
      });
  
      xhr.addEventListener('load', () => {
        const blob = xhr.response;
        resolve(URL.createObjectURL(blob));
      });
  
      xhr.addEventListener('error', reject);
  
      xhr.send();
    });
  }
  loadTexture(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      let previousLoadedBytes = 0;
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
  
      
  
      xhr.addEventListener('progress', (event) => {
      //  if (event.lengthComputable) {
          const newLoadedBytes = event.loaded - previousLoadedBytes;
          this.totalLoadedBytes += newLoadedBytes;
          previousLoadedBytes = event.loaded
         // this.calculatedTotalBytes += event.total;
          this.updateProgressBar(this.calculateProgress());
        //}
      });
  
      xhr.addEventListener('load', () => {
        const blob = xhr.response;
        const texture = this.textureLoader.load(URL.createObjectURL(blob), resolve);
      });
  
      xhr.addEventListener('error', reject);
  
      xhr.send();
    });
  }
  
  loadModel(url) {
    return new Promise((resolve, reject) => {
      let previousLoadedBytes = 0;
  
      this.gltfLoader.load(
        url,
        (gltf) => {
          console.log('loaded done')
        //  const newLoadedBytes = this.totalLoadedBytes - previousLoadedBytes;
      //    this.updateProgressBar(this.calculateProgress());
          resolve(gltf);
        },
        (xhr) => {
          console.log('loadeModel'+xhr)
         // if (xhr.lengthComputable) {

            const newLoadedBytes = xhr.loaded - previousLoadedBytes;
            this.totalLoadedBytes += newLoadedBytes;
            previousLoadedBytes = this.totalLoadedBytes

            this.updateProgressBar(this.calculateProgress());
          //}
        },
        reject
      );
    });
  }
  loadModel2(url) {
    return new Promise((resolve, reject) => {
      let previousLoadedBytes = 0;
      console.log("---------------- > "+ this.totalLoadedBytes)
      this.gltfLoader2.load(
        url,
        (gltf) => {
        //  const newLoadedBytes = this.totalLoadedBytes - previousLoadedBytes;
    ///      this.updateProgressBar(this.calculateProgress());
          resolve(gltf);
        },
        (xhr) => {
          console.log("---------------- > "+ this.totalLoadedBytes)
         // if (xhr.lengthComputable) {

            const newLoadedBytes = xhr.loaded - previousLoadedBytes;
            this.totalLoadedBytes += newLoadedBytes;
            previousLoadedBytes = xhr.loaded

            this.updateProgressBar(this.calculateProgress());
          //}
        },
        reject
      );
    });
  }

  updateProgressBar(percentage) {
    //console.log( percentage)
    // Update width of the progress bar
    console.log( percentage)
    //document.getElementById("loading-quote").innerHTML = Math.floor(percentage*100)

    const inset = (1 - percentage) * 100;
  
    // get all elements with the class '.cls-1' and loop through them
    const elements = document.querySelectorAll('.cls-1');
    elements.forEach((el) => {
      el.style.clipPath = `inset(${inset}% 0 0 0)`;
    });
  }

  updateQuote() {
    // Choose a random quote
    const randomIndex = Math.floor(Math.random() * this.quotes.length);
    const randomQuote = this.quotes[randomIndex];

    // Update the quote
    this.quote.innerText = randomQuote;
  }

  printTotalLoadedBytes() {
    console.log(`Totals bytes loaded: ${this.totalLoadedBytes}`);
  }

  calculateProgress() {
    console.log( this.totalLoadedBytes+"--"+this.totalBytes)
    return (this.totalLoadedBytes / this.totalBytes) ;
  }

  //10860160
  // 1171340
  // 248813
  //  884953
  // 1051212
  loadAssets() {
    const assets = [
      { method: this.loadModel, url: shipURL, name: 'SHIP' },
      
      //{ method: this.loadTexture, url: hotspotTextureURL, name: 'HOTSPOT_TEXTURE' },
      { method: this.loadTexture, url: waterNormalsURL, name: 'WATER_NORMALS' },
    //  { method: this.loadTexture, url:  character, name: 'CHARACTER' },
   { method: this.loadLUT, url:  '/assets/lut/Cubicle 99.CUBE', name: 'CUBICLE' },
   { method: this.loadLUT, url:  '/assets/video/jumpscare.mp4', name: 'SCARE' },
      
    //  { method: this.loadLUT, url: bourbon, name: 'BOURBON' },
      //{ method: this.loadLUT, url: chemical, name: 'CHEMICAL' },
     // { method: this.loadLUT, url: clayton, name: 'CLAYTON' }

       
      
       




    ];
  
    return assets.reduce((promiseChain, asset) => {
      return promiseChain.then(() => new Promise((resolve, reject) => {
        asset.method(asset.url)
          .then((data) => {
            console.log( data)
            this.updateProgressBar(this.calculateProgress());
            asset.data = data
            resolve({ name: asset.name, data: data });
          })
          .catch(reject);
      }));
    }, Promise.resolve())
    .then(() => {
      this.printTotalLoadedBytes();
      window.LOADER = {};
      console.log( assets[0].data)
      assets.forEach((asset) => {
        window.LOADER[asset.name] = asset.data;
      });
      console.log(window.LOADER["SHIP"])
    })
    .catch((error) => {
      console.error("Error loading assets: ", error);
    });
  }

  
  
  
  
  
  hide(){
    document.getElementById("loader-container").style.display="none"
  }
}

export default SiteLoader;
