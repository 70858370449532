import * as THREE from 'three';
import { TweenLite } from "gsap";  // GSAP library for smooth animations
import hotspotTextureURL from '../textures/hotspot.png';
import hotspotClickedTextureURL from '../textures/hotspot_clicked.png'; // Your texture file path
import TextureAnimator from './TextureAnimator';
import { Tween } from 'gsap/gsap-core';
class Hotspots {
    
  constructor(scene, controls, camera,overlay,centralDispatcher) {
  
    this.centralDispatcher = centralDispatcher;
    this.scene = scene;
    this.controls = controls;
    this.camera = camera;
    this.overlay = overlay;
    this.hotspots = [];
    this.hotspotContainer = new THREE.Group();
    this.time= 0;

    this.currentHotspot = 0;
 
    this.data = window.i18next.t('videos', { returnObjects: true });

    this.initHotspots();
    this.centralDispatcher.addEventListener("HotSpotFound",this.moveCameraTo.bind(this))
    this.centralDispatcher.addEventListener("changeHotspotColor",this.changeHotspotMaterial.bind(this))
                
    
    }  

    initHotspots() {
      this.map = new THREE.TextureLoader().load( hotspotTextureURL );
      this.material = new THREE.SpriteMaterial( { map: this.map,transparent:true,opacity:0 } );

      this.map1 = new THREE.TextureLoader().load( hotspotClickedTextureURL );
      this.materialClick = new THREE.SpriteMaterial( { map: this.map1 } );


      this.data.forEach((hotspot,index) => {
        this.addHotspot(hotspot,index )
      });
      window.HOTSPOTS = this.hotspots;
    
      window.LOADER["SHIP"].scene.add( this.hotspotContainer)
      
    }
    fadeIn(){
      Tween.fromTo(this.material,1,{opacity:0},{opacity:1})
    }
    addHotspot( data,index ) {
      const hotspot = new THREE.Sprite( this.material );


       
        hotspot.position.set( data.x, data.y, data.z );
        let startSC =  200+Math.random()*100;
       hotspot.scale.set (startSC, startSC, startSC)
        hotspot.sc = 300;
       /// hotspot.rotation.z=1.57
       // hotspot.rotation.x=1.57
        hotspot.data = index;
    ///    hotspot.rotation.z = Math.random()*2;
        this.hotspotContainer.add(hotspot)
        this.hotspots.push(hotspot);

    }

    isHotspot(object) {
        return this.hotspots.includes(object);
    }

    moveCameraTo(event) {
      
        let data = event.data
        this.centralDispatcher.dispatchEvent( { type: 'overlayShow', data: data } )
        return
        TweenLite.to(this.controls.target, 0, { x: data.control.x, y: data.control.y, z: data.control.z });
        TweenLite.to(this.camera.position, 0, { 
            x : data.cam.x,  // Adjust as needed
            y : data.cam.y,  // Adjust as needed
            z : data.cam.z,  // Adjust as needed
            onUpdate: () => this.controls.update(),
            onComplete:   () =>  this.centralDispatcher.dispatchEvent( { type: 'overlayShow', data: data.url } )// Update controls in each frame
        });
    }
    changeHotspotMaterial(event){
      this.hotspots[event.data].clicked = true;
      this.hotspots[event.data].material =   this.materialClick;
    }

    
    rotate(){
      
      this.hotspots.forEach(hotspot => {
      hotspot.rotation.y-=0.04;
      });

    }
    pulsate(delta){
      this.hotspots.forEach(hotspot => {
        if(!hotspot.clicked){
          const scale = hotspot.sc + 100 * Math.sin(this.time/10);
          hotspot.scale.set(scale, scale, scale);
  
        }
      });

  }
    update(delta) {
      this.time++;
      this.pulsate();
    
    }
}

export default Hotspots;