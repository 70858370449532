import * as THREE from 'three';
import shipURL from '../models/ship5.glb';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
class Ship {

    constructor(scene) {
    
        this.scene = scene;
        this.ship = null;
        this.time = 0;
    
    }

    load() {

        this.shipBlock = new THREE.Group();

        this.ship = window.LOADER["SHIP"].scene;
        this.ship.position.set(0, 0, 0);
        this.ship.rotation.set(0, 0.5, 0);
        this.ship.scale.set(0.025, 0.025, 0.025);
        this.scene.add(this.ship);
        
        this.animations = window.LOADER["SHIP"].animations;
        if (this.animations && this.animations.length) {
            var mixer = new THREE.AnimationMixer(this.ship);
            for (var i = 0; i < this.animations.length; i++) {
                var animation = this.animations[i];
                mixer.clipAction(animation).play();
            }
    
            // Save the animation mixer to the scene if you want to update it later
            this.ship.mixer = mixer;
        }
        
        
        
    }

    wobble(){
    
        this.time += 0.02;
      if(this.ship) {
        this.ship.position.y = 0 + Math.sin(this.time) * 2;
        this.ship.rotation.x =0+ Math.sin(this.time) * -0.05   ;
        //this.ship.rotation.z =0+ Math.sin(this.time+0.01) * 0.02;
        } 
    
    }
    update( delta ) {
    
        this.wobble();
        this.animate(delta);
    
    }

    animate( delta ) {


        if (this.ship.mixer ) this.ship.mixer.update(delta); 

    }

}

export default Ship;
