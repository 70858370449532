import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { LUTPass } from 'three/examples/jsm/postprocessing/LUTPass.js';
import { LUTCubeLoader } from 'three/examples/jsm/loaders/LUTCubeLoader.js';
import { VignetteShader } from 'three/examples/jsm/shaders/VignetteShader';
import { Tween } from 'gsap/gsap-core';

class PostPro {
    constructor(scene, renderer, camera, ready) {
        // Parameters for post-processing effects
        this.params = {
            enabled: true,
            intensity: 0.5,
        };

        // Shader uniforms for color correction
        this.shaderUniforms = {
            brightness: 1,
            contrast: 1.5,
            saturation: 0.8,
            hue: 0,
            vignetteOffset: 1.4,
            vignetteDarkness: 1.23,
        };

        // Initialize EffectComposer
        this.composer = new EffectComposer(renderer);

        // Add a RenderPass to the composer
        let renderPass = new RenderPass(scene, camera);
        this.composer.addPass(renderPass);

        // Custom color correction shader
        let colorShader = {
            uniforms: {
                tDiffuse: { value: null },
                brightness: { value: 1 },
                contrast: { value: 1.5 },
                saturation: { value: 0.7 },
                hue: { value: 0 },
            },
		vertexShader: `
			varying vec2 vUv;
	
			void main() {
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader: `
			varying vec2 vUv;
			uniform sampler2D tDiffuse;
			uniform float brightness;
			uniform float contrast;
			uniform float saturation;
			uniform float hue;
	
			// HUE, SATURATION, BRIGHTNESS functions
			// reference: http://www.chilliant.com/rgb2hsv.html
			
			vec3 RGB2HSV(vec3 rgb) {
				vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
				vec4 p = mix(vec4(rgb.bg, K.wz), vec4(rgb.gb, K.xy), step(rgb.b, rgb.g));
				vec4 q = mix(vec4(p.xyw, rgb.r), vec4(rgb.r, p.yzx), step(p.x, rgb.r));
				float d = q.x - min(q.w, q.y);
				float e = 1.0e-10;
				return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
			}
	
			vec3 HSV2RGB(vec3 hsv) {
				vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
				vec3 p = abs(fract(hsv.xxx + K.xyz) * 6.0 - K.www);
				return hsv.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), hsv.y);
			}
	
			void main() {
				vec4 color = texture2D(tDiffuse, vUv);
				vec3 hsv = RGB2HSV(color.rgb);
				hsv.x += hue;
				hsv.y *= saturation;
				hsv.z *= brightness;
				color.rgb = HSV2RGB(hsv) * contrast;
				gl_FragColor = color;
			}
		`
	    };
	    // Create a ShaderPass with the custom colorShader
        this.colorPass = new ShaderPass(colorShader);
        this.colorPass.renderToScreen = true;
        this.composer.addPass(this.colorPass);

        // Add LUTPass to the composer
        let lutPass = new LUTPass();
       this.composer.addPass(lutPass);
        this.lutPass = lutPass;

        // Add VignetteShader pass to the composer (commented out for now)
        // const vignettePass = new ShaderPass(VignetteShader);
        // vignettePass.uniforms['offset'].value = 1.4;
        // vignettePass.uniforms['darkness'].value = 1.3;
        // this.composer.addPass(vignettePass);
        // this.vignettePass = vignettePass;

        // Enable LUTPass and load the LUT texture
        lutPass.enabled = true;
        new LUTCubeLoader().load('assets/lut/Cubicle 99.CUBE', (result) => {
            lutPass.lut = result.texture;
            lutPass.intensity = this.params.intensity;
            lutPass.renderToScreen = true;
            ready();
            this.fadeIn();
        });
/*
        const spriteRenderPass = new RenderPass(spriteScene, camera);
// Make sure this pass doesn't clear the previous rendering
        spriteRenderPass.clear = false;
        spriteRenderPass.renderToScreen = false;
       this.composer.addPass(spriteRenderPass);
*/
    }

    fadeIn() {
        // Tween to fade in the brightness uniform
        Tween.fromTo(this.colorPass.uniforms.brightness, 6, {delay:2, value: 0 }, { value: 1 });
        
    }
}

export default PostPro;