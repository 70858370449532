import i18next from "i18next";

class Localisation {
  constructor(preload) {
    this.preload = preload;

    fetch( window.siteurl+'/assets/copy.json')
      .then(response => response.json())
      .then(en => {
        console.log(this.preload);
        window.i18next = i18next;
        i18next.init({
          lng: window.lang,
          fallbackLng: "en",
          resources: {
            en: { translation: en }
          }
        }, (err, t) => {
          if (err) return console.log('something went wrong loading', err);

          let movieIsOut = false;
          var releaseDate = new Date(Date.parse(i18next.t('releaseDate')));
          var currentDate = new Date();
          if (currentDate >= releaseDate) movieIsOut = true;

       document.getElementById('img-load').src = (movieIsOut) ? i18next.t('logo_preload_release') : i18next.t('logo_preload')
       document.getElementById('logoBtn').href = i18next.t('site-link');
     //  document.getElementById('logo2').src = i18next.t('logo_preload');
       document.getElementById('logo').src = (movieIsOut) ? i18next.t('logo_preload_release') : i18next.t('logo_preload')
       document.getElementById('img-load').classList.remove("hidden");
       document.getElementById('dragon').classList.remove("hidden");
  //     document.getElementById('loading-quote').classList.remove("hidden");


       document.querySelector(".social_fb").style.display = i18next.t("social_fb_display");
       document.querySelector(".social_fb").href = i18next.t("social_fb");


       document.querySelector(".social_insta").href = i18next.t("social_insta");
       document.querySelector(".social_insta").style.display = i18next.t("social_insta_display");
       
       document.querySelector(".social_tik").href = i18next.t("social_tik");
       document.querySelector(".social_tik").style.display = i18next.t("social_tik_display");

       document.querySelector(".social_x").href = i18next.t("social_x");
       document.querySelector(".social_x").style.display = i18next.t("social_x_display");

       document.querySelector(".social_you").href = i18next.t("social_you");
       document.querySelector(".social_you").style.display = i18next.t("ssocial_you_display");


        document.getElementById( "add_choices").style.display = i18next.t("add_choices_display");
        document.getElementById( "add_choices").querySelector("a").href = i18next.t("add_choices_url");
        document.getElementById( "add_choices").querySelector("a").innerHTML = i18next.t("add_choices");
     
        document.getElementById( "terms").style.display = i18next.t("terms_display");
        document.getElementById( "terms").querySelector("a").href = i18next.t("terms_url");
        document.getElementById( "terms").querySelector("a").innerHTML = i18next.t("terms");

        document.getElementById( "cookie").style.display = i18next.t("cookie_display");
        document.getElementById( "cookie").querySelector("a").href = i18next.t("cookie_url");
        document.getElementById( "cookie").querySelector("a").innerHTML = i18next.t("cookie");
     
        document.getElementById( "your_privacy").style.display = i18next.t("your_privacy_display");
        document.getElementById( "your_privacy").querySelector("a").href = i18next.t("your_privacy_url");
        document.getElementById( "your_privacy").querySelector("a").innerHTML = i18next.t("your_privacy");

        document.getElementById( "ca").style.display = i18next.t("ca_display");
        document.getElementById( "ca").querySelector("a").href = i18next.t("ca_url");
        document.getElementById( "ca").querySelector("a").innerHTML = i18next.t("ca");

        document.getElementById( "filmrating").style.display = i18next.t("filmrating_display");
        document.getElementById( "filmrating").querySelector("a").href = i18next.t("filmrating_url");
        document.getElementById( "filmrating").querySelector("a").innerHTML = i18next.t("filmrating");

        document.getElementById( "mpa").style.display = i18next.t("mpa_display");
        document.getElementById( "mpa").querySelector("a").href = i18next.t("mpa_url");
        document.getElementById( "mpa").querySelector("a").innerHTML = i18next.t("mpa");

        document.getElementById( "feedback").style.display = i18next.t("feedback_display");
        document.getElementById( "feedback").querySelector("a").href = i18next.t("feedback_url");
        document.getElementById( "feedback").querySelector("a").innerHTML = i18next.t("feedback");

        document.getElementById( "rights").innerHTML = i18next.t("rights");
        document.getElementById( "rating").src = i18next.t("rating");
        

        
        this.preload();
      });
    });
}
}

export default Localisation;